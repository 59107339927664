import {
    getAuth,
    signInWithCustomToken
} from "firebase/auth";
import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, limit, addDoc, deleteDoc, doc, setDoc } from "firebase/firestore";
import PropTypes from 'prop-types';
import {getAuthToken} from '../chat/service';
import { auth, db } from './firebaseConfig'
import NavNar from 'components/common/navbar/NavNar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Creators from 'components/creators/Creators';
import MessageContainer  from 'components/chat/messageContainer/MessageContainer';
import {getUsers} from './service';

const ChatApp = props => { 
    const MESSAGE_LIMIT = 100;   
    const COLLECTION = {
        GROUPS: 'groups',
        CHATS: 'chats'
    };    

    const [user, setUser] = useState(null);    
    const [chats, setChats] = useState([]);
    const [creators, setCreators] = useState([]);
    const [selectedCreator, setSelectedCreator] = useState(null);
    const [selectedCreatorName, setSelectedCreatorName] = useState(null);
    const [message, setMessage] = useState(null);    

    useEffect(() => {
        getAuthToken().then((response)=> {
            console.log(`response ${JSON.stringify(response)}`);            
            signInWithCustomToken(auth, response.response).then((userCredential) => {
                setUser(userCredential.user);            
                console.log(`user: ${JSON.stringify(userCredential.user)}`);
                readData();
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(`errorCode: ${errorCode}`);
                console.log(`errorMessage: ${errorMessage}`);
            });
        });
    }, []);

    const readData = async () => {
        const querySnapshot = await getDocs(collection(db, COLLECTION.GROUPS));        
        let creatorIds = [];
        querySnapshot.forEach((doc) => {
            creatorIds.push(doc.id);
        });
        getUsers({
            userIds: creatorIds
        }).then((resp) => {            
            setCreators(resp);            
            setSelectedCreator(resp[0].userId);
            setSelectedCreatorName(resp[0].fullName);
        }).catch((err) => {
            console.log("Error");
            alert(err.errorMessage || err.errorCode);
        });
        
        readChatDocs(creatorIds[0]);
    }

    const readChatDocs = async(groupId) => {
        const q = query(collection(db, COLLECTION.CHATS), where("creatorId", "==", groupId), orderBy("postedTime"), limit(MESSAGE_LIMIT));
        const chatSnapshot = await getDocs(q);
        let chats = [];
        chatSnapshot.forEach((doc) => {
            chats.push({...doc.data(), id: doc.id});
        });
        setChats(chats);        
        setTimeout(() => {
            scrollDown();
        }, 500);
    };

    const scrollDown = ()=> {
        document.getElementsByClassName('msger-input')[0].scrollIntoView({ behavior: 'smooth' });
    }

    const onCreatorSelect = async(id, fullName) => {
        setChats([]);
        setSelectedCreator(id);
        readChatDocs(id);
        setSelectedCreatorName(fullName);
    }

    const sendMessage = async(asStudent) => {
        getUsers({
            userIds: [selectedCreator],
            botUser: asStudent
        }).then(async(resp) => {
           console.log("resp ", resp);
           if(resp.length) {
               const sender = resp[0];
                let messagePayLoad = {
                    "creatorId": selectedCreator, 
                    "message": message,
                    "postedTime": new Date().getTime(), 
                    "sentBy": { 
                        "email": sender.email, 
                        "firstName": sender.firstName, 
                        "fullName": sender.fullName, 
                        "grade": sender.grade, 
                        "lastName": sender.lastName, 
                        "locationInfo": {
                            "state": sender?.locationInfo?.state
                        }, 
                        "profilePicUrl": sender.profilePicUrl, 
                        "role": sender.role, 
                        "userId": sender.userId
                    }
                }

                try {
                    const docRef = await addDoc(collection(db, COLLECTION.CHATS), messagePayLoad);
                    console.log("Document written with ID: ", docRef.id);
                    let _chats = chats;
                    _chats.push(messagePayLoad);
                    setChats(_chats);
                    setMessage("");
                    setTimeout(() => {
                        scrollDown();
                    }, 500);                  
                } catch (e) {
                    console.error("Error adding document: ", e);
                }
           }           
        }).catch((err) => {
            console.log("Error");
            alert(err.errorMessage || err.errorCode);
        });
    };

    const deleteChat = async(messageId) => {
        try {
            console.log(messageId);
            await deleteDoc(doc(db, COLLECTION.CHATS, messageId));
            console.log("Entire Document has been deleted successfully.");
            setChats([]);     
            readChatDocs(selectedCreator);
        } catch (error) {
            console.log(error);
        }
    }

    const onBoardCreator = (emailId) => {
        getUsers({
            emailId: emailId            
        }).then(async(resp) => {
            console.log(resp);
            if(resp.length && resp[0].userId) {
                const creator = resp[0];
                if(creator.role === "CREATOR") {
                    let groupPayload = {   
                        email: creator.email,
                        groupImage: creator.profilePicUrl,
                        members: [""],
                        name: creator.fullName,
                        pic: creator.profilePicUrl
                    };
                    try {                        
                        const docRef = await setDoc(doc(db, COLLECTION.GROUPS, creator.userId), groupPayload);;
                        console.log("Document written with");
                        alert("Creator Successfully onboarder!");
                        window.location.reload();
                    } catch (e) {
                        console.error("Error adding document: ", e);
                    }
                } else {
                    alert("No creator found for given email!");
                }                
            } else {
                alert("No user found for given email!")
            }
        }).catch((err) => {
            console.log("Error");
            alert(err.errorMessage || err.errorCode);
        });
    }

    const loadChatMore = ()=> {}

    return (
        <div> 
            <NavNar /> 
            {
                user && <div>
                    <Container className="chatContainer">
                        <Row>
                            <Col xs={4} sm={2}>
                                <Creators onCreatorEmailSubmit={onBoardCreator} creatorList={creators} onCreatorSelect={onCreatorSelect} selectedCreator={selectedCreator} />
                            </Col>
                            <Col xs={8} sm={10}>
                                <MessageContainer onMessageDelete={deleteChat} message={message} setMessage={setMessage} name={selectedCreatorName} chats={chats} loadMore={loadChatMore} sendMessage={sendMessage} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </div>
    )
}

ChatApp.propTypes = {}

export default ChatApp