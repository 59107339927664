import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDs4SdO7YUexBCZmq2935HG9sd2o1GY0VQ",
    authDomain: "wagmi-qa.firebaseapp.com",
    databaseURL: "https://wagmi-qa-default-rtdb.firebaseio.com",
    projectId: "wagmi-qa",
    storageBucket: "wagmi-qa.appspot.com",
    messagingSenderId: "104781298024",
    appId: "1:104781298024:web:5db53ebc02fa10d42abedd",
    measurementId: "G-Q38XXZN51H"
})

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db, auth }